<template lang="pug">
  main#main.oauth-login.d-flex.flex-grow-1.align-items-center.justify-content-center
    wc-loaders-cube
</template>

<script>
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'

export default {
  name: 'oauth-login',
  components: {
    WcLoadersCube,
  },
}
</script>
